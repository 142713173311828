@use '../variables';

nav {
    width: 100%;
  
    .nav-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 2rem;
      grid-row-gap: 0;
      align-items: stretch;
      justify-items: stretch;
      width: 85%;
      max-width: 135rem;
      margin: 0 auto;
      padding: 2rem 0;
  
      .nav-item.nav-logo {
        grid-column: auto / span 3;
        display: grid;
        align-items: stretch;
        justify-items: center;

        a {
            justify-self: left;
            display: grid;
            align-items: center;

            img.logo {         
              height: 4rem;
            }
        }
      }
    }
  }
  
  @media (max-width: 1280px) {
    nav {
  
      .nav-container {
  
        .nav-item.nav-logo {
  
          img.logo {
            height: 3.5rem;
          }
  
        }
  
      }
  
    }
  }
  
  @media (max-width: 720px) {
    nav {
  
      .nav-container {
  
        .nav-item.nav-logo {
  
          img.logo {
            height: 3rem;
          }
  
        }
  
      }
  
    }
  }

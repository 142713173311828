@use './variables';

.wrapper {
  display: flex;
  flex-direction: column;
  place-items: center center;
}

header {
  width: 100%;
  border-bottom: 0.5px solid variables.$color-grey;

  h2 {
    margin: 0 0 6rem;
    font-size: 6rem;
    line-height: 130%;
    font-weight: bold;
  }

  .header-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    justify-content: left;
    width: 85%;
    max-width: 135rem;
    margin: 0 auto;
    padding: 8rem 0 32rem;
    text-align: left;
    color: variables.$color-black;

    .title {
      grid-column: auto / span 10;
    }

    .subtitle {
      grid-column: auto / span 8;
    }
  }
}

@media (max-width: 1280px) {
  header {
    h2 {
      margin: 0 0 4rem;
      font-size: 4rem;
      line-height: 130%;
      font-weight: bold;
    }
  }
}

@media (max-width: 720px) {
  header {
    h2 {
      margin: 0 0 3rem;
      font-size: 3rem;
      line-height: 130%;
      font-weight: bold;
    }

    .header-container {
      padding: 8rem 0 8rem;

      .title {
        grid-column: auto / span 12;
      }

      .subtitle {
        grid-column: auto / span 12;
      }
    }
  }
}

@use '../variables';

footer {
  width: 100%;

  .footer-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    place-items: center stretch;
    width: 85%;
    max-width: 135rem;
    margin: 0 auto;
    padding: 4rem 0;

    .footer-item {
      grid-column: auto / span 1;
      display: grid;
      place-items: center stretch;

      a {
        display: grid;
        justify-self: left;
        align-self: center;

        svg {              
          width: 8rem;
          height: 8rem;
        }
      }
    }
    .footer-item:nth-child(4) {
      grid-column: auto / span 9;
      justify-self: end;
    }
  }
}
  
@media (max-width: 1280px) {
  footer {
    .footer-container {
      .footer-item {
        grid-column: auto / span 1;

        a {  
            svg {              
              width: 6rem;
              height: 6rem;
            }
          }  
        }  
      }  
    }
  }
  
@media (max-width: 720px) {
  footer {
    .footer-container {
      .footer-item {
        grid-column: auto / span 2;

        a {  
          svg {              
            width: 4rem;
            height: 4rem;
          }
        }  
      }
      .footer-item:nth-child(4) {  
        grid-column: auto / span 6;
      }
    }  
  }
}

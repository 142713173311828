@use './variables';

html {
  font-size: variables.$base-font-size;
  background-color: variables.$color-white;
}

body {
  margin: 0;
  padding: 0;
  font-family: variables.$base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  line-height: 160%;
  font-weight: 400;
}

code {
  font-family: variables.$code-font-family;
}

h1 {
  margin: 0 0 8rem;
  font-size: 14rem;
  line-height: 105%;
  font-weight: 700;
}

h2 {
  margin: 0 0 7rem;
  font-size: 9rem;
  line-height: 110%;
  font-weight: 700;
}

h3 {
  margin: 0 0 6rem;
  font-size: 6rem;
  line-height: 130%;
  font-weight: 700;
}

h4 {
  margin: 0 0 4rem;
  font-size: 4rem;
  line-height: 150%;
  font-weight: 700;
}

p {
  margin: 0 0 2rem;
  font-size: 2rem;
  line-height: 160%;
  font-weight: 400;
}

a {
  color: variables.$color-black;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: variables.$color-primary;
  }
}

.inter-font-family {
  font-family: 'Inter', sans-serif;
}

@media (max-width: 1280px) {
  body {
    font-size: 2rem;
    line-height: 160%;
    font-weight: 400;
  }

  h1 {
    margin: 0 0 6rem;
    font-size: 10rem;
    line-height: 105%;
    font-weight: 700;
  }

  h2 {
    margin: 0 0 5rem;
    font-size: 7rem;
    line-height: 110%;
    font-weight: 700;
  }

  h3 {
    margin: 0 0 4rem;
    font-size: 4rem;
    line-height: 130%;
    font-weight: 700;
  }

  h4 {
    margin: 0 0 3rem;
    font-size: 3rem;
    line-height: 150%;
    font-weight: 700;
  }

  p {
    margin: 0 0 2rem;
    font-size: 2rem;
    line-height: 160%;
    font-weight: 400;
  }
}

@media (max-width: 720px) {
  body {
    font-size: 2rem;
    line-height: 160%;
    font-weight: 400;
  }

  h1 {
    margin: 0 0 4rem;
    font-size: 6rem;
    line-height: 105%;
    font-weight: 700;
  }

  h2 {
    margin: 0 0 3rem;
    font-size: 3.5rem;
    line-height: 110%;
    font-weight: 700;
  }

  h3 {
    margin: 0 0 3rem;
    font-size: 3rem;
    line-height: 130%;
    font-weight: 700;
  }

  h4 {
    margin: 0 0 2.5rem;
    font-size: 2.5rem;
    line-height: 150%;
    font-weight: 700;
  }

  p {
    margin: 0 0 2rem;
    font-size: 2rem;
    line-height: 160%;
    font-weight: 400;
  }
}

@use '../variables';

section {
  width: 100%;
  border-bottom: 0.5px solid variables.$color-grey;

  p {
    margin: 0 0 4rem;
    font-size: 4rem;
    line-height: 150%;
    font-weight: normal;
  }

  .section-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 100rem;
    grid-column-gap: 2rem;
    grid-row-gap: 0;
    justify-content: left;
    align-items: center;
    width: 85%;
    max-width: 135rem;
    margin: 0 auto;
    padding: 8rem 0;
    text-align: left;
    color: variables.$color-black;

    .section-item {
      grid-column: auto / span 12;

      .section-title-list {
        display: block;
        margin: 8rem 0;
      }

      .section-subtitle-list {
        display: block;
        margin: 0 0 14rem;
        font-size: 3rem;
        line-height: 150%;
      }

      .section-subtitle-list:nth-child(1) {
        line-height: 200%;
      }

      .section-subtitle-list:nth-child(3) {
        margin: 0;
        line-height: 100%;
      }
    }

    .browser-mockup {
      position: relative;
      padding: 23rem 8rem 18rem;
      border-radius: 0.5rem;
      border: 1px solid #aaa;
      overflow-y: hidden;
      
      h2 {
        margin: 0 0 5rem;
        font-size: 9rem;
        line-height: 110%;
      }

      p {
        font-size: 3.5rem;
        font-size: 9rem;
        line-height: 110%;
        font-weight: bold;
      }

      .section-subtitle {
        position: absolute;
        bottom: -6.5rem;
      }
    }
    
    .browser-mockup:before {
      display: block;
      position: absolute;
      content: '';
      top: 0.75em;
      left: 1em;
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      background-color: #f44;
      box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
    }

    .browser-mockup:after {
      display: block;
      position: absolute;
      content: '';
      top: 0.4em;
      left: 5.5em;
      width: calc(100% - 6em);
      height: 1.2em;
      border-radius: 0.25rem;
      background-color: white;
    }
    
    .browser-mockup > * {
      display: block;
    }

    .line-height {
      h3:nth-child(1) {
        line-height: 150%;
      }
      h3:nth-child(2) {
        line-height: 110%;
      }
      h3:nth-child(3) {
        line-height: 70%;
      }
    }
  }
}

@media (max-width: 1280px) {
  section {
    p {
      margin: 0 0 3rem;
      font-size: 3rem;
      line-height: 150%;
      font-weight: normal;
    }

    .section-container {

      .section-item {
  
        .section-subtitle-list {
          margin: 0 0 10rem;
        }
      }

      .browser-mockup {
        padding: 15rem 6rem 12rem;
      
        h2 {
          margin: 0 0 3rem;
          font-size: 5rem;
          line-height: 120%;
        }
  
        p {
          font-size: 5rem;
          line-height: 120%;
        }

        .section-subtitle {
          bottom: -4.4rem;
        }
      }
    }
  }
}
  
@media (max-width: 720px) {
  section {
    p {
      margin: 0 0 2.5rem;
      font-size: 2.25rem;
      line-height: 150%;
      font-weight: normal;
    }

    .section-container {
      grid-template-rows: 60rem;

      .section-item {
        grid-column: auto / span 12;

        .section-title-list {
          display: block;
          margin: 4rem 0;
        }

        .section-subtitle-list {
          margin: 0 0 7rem;
          font-size: 2rem;
          line-height: 150%;
        }
      }

      .browser-mockup {
        padding: 5.5em 3rem 5.5rem;
      
        h2 {
          margin: 0 0 2rem;
          font-size: 2.5rem;
          line-height: 130%;
        }
  
        p {
          font-size: 2.5rem;
          line-height: 130%;
        }

        .section-subtitle {
          bottom: -3.3rem;
        }
      }
    }
  }
}

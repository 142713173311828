$base-font-family: 'Noto Sans', sans-serif;
$code-font-family: monospace;
$base-font-size: 8px;

$color-black: #242424;
$color-white: #F1F1F1;
$color-dark: #363636;
$color-grey: #bababa;
$color-blue: #5C5CFF;
$color-background-lightblue: #E8E9EB;
$color-primary: #1B1725;
$color-secondary: #534B62;
$color-tertiary: #A499B3;
$color-accent-light: #927ABF;
$color-accent-dark: #59468A;
$color-background-red: #CE0000;
